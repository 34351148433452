<template>
  <div class="w-full bg-[#01508c] flex">
    <img src="@/assets/logoTim.png" alt="TIM - Logo" class="mx-auto h-full" />
  </div>
  <FirstPage v-if="showFirst" @toggle-view="showSecondView()" />
  <SecondPage v-else />
</template>

<script>
import FirstPage from "./components/FirstPage.vue";
import SecondPage from "./components/SecondPage.vue";

export default {
  name: "App",
  components: {
    FirstPage,
    SecondPage,
  },
  data() {
    return {
      showFirst: true,
    };
  },
  methods: {
    showSecondView() {
      this.showFirst = false;
    },
  },
};
</script>
