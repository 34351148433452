<template>
  <div class="w-full text-center">
    <div class="text py-14 px-4">
      <h1 class="block text-2xl font-bold text-[#01508c]">
        Resolva aqui o seu problema
      </h1>
      <span class="block text-2xl text-[#01508c]"
        >Selecione o seu produto TIM dentre as opções abaixo:
      </span>
    </div>
    <div class="flex flex-col lg:flex-row justify-center">
      <a href="https://www.tim.com.br/sobre-a-tim/fale-conosco#4cb5">
        <div class="chat">
          <img src="@/assets/movel.png" class="mx-auto px-14 pt-14 pb-6" />
          <span class="text-[#01508c] font-bold">Móvel e Fixo</span>
        </div>
      </a>
      <a href="https://timultrafibra.online/digital/">
        <div class="ouvidoria">
          <img
            src="@/assets/TIMUltrafibra.png"
            class="mx-auto px-14 pt-14 pb-6"
          />
          <span class="text-[#01508c] font-bold">Banda Larga e VOIP</span>
        </div>
      </a>
    </div>
  </div>
</template>
