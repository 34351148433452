<template>
  <div class="w-full text-center">
    <div class="text py-14 px-4">
      <h1 class="block text-2xl font-bold text-[#01508c]">
        Queremos vê-lo satisfeito com nosso atendimento
      </h1>
      <span class="block text-2xl text-[#01508c]"
        >Temos uma equipe especializada para resolver sua solicitação</span
      >
      <span class="block text-2xl text-[#01508c]"
        >Escolha o seu canal de preferência e fale conosco</span
      >
    </div>
    <div class="flex flex-col lg:flex-row justify-center">
      <div class="ligamos cursor-pointer" @click="showSecondView()">
        <img src="@/assets/ligamos.jpg" class="mx-auto px-14 pt-14 pb-6" />
        <span class="text-[#01508c]"
          >Receba o retorno em até 4 horas úteis</span
        >
      </div>
      <a href="https://webchatv2.boteria.com.br/666788578f4dbccb7d914842">
        <div class="chat">
          <img src="@/assets/chat.jpg" class="mx-auto px-14 pt-14 pb-6" />
          <span class="text-[#01508c]">Fale agora com um especialista</span>
        </div>
      </a>
      <a href="https://www.tim.com.br/para-voce/atendimento/ouvidoria">
        <div class="ouvidoria">
          <img src="@/assets/ouvidoria.png" class="mx-auto px-14 pt-14 pb-6" />
          <span class="text-[#01508c]">Contate nossa ouvidoria</span>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    showSecondView() {
      this.$emit("toggle-view");
    },
  },
};
</script>
